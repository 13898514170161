import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => {
  require('./privacy.scss')

  return (
    <Layout className={'privacy-page'}>
      <SEO title="Privacy Policy" />
      <div>
        <div className={'policy-header'}>
          <h1><Link to={'/'}>{'<'}</Link></h1>
          <h1>Privacy Policy</h1>
        </div>
        <div className={'policy-container'}>
          <p>Natal Charts Notebook is a free application with paid premium features. This App is provided by Kasra Rahjerdi at no cost and is intended for use as is.</p>
          <p>If you choose to use my App, then you agree to the collection and use of information in relation to this policy. The Personal Information that I collect is used for providing and improving the Service. I will not use or share your information with anyone except as described in this Privacy Policy.</p>

          <h3>Information Collection and Use</h3>

          <p>While using our App, we collect anonymized data regarding status of charts, unlocks, and number of charts created.</p>
          <p>The app does not store any personally identifiable information, the name and address you provide in app are stored only on your device's local memory.</p>
          <p>The app uses some third party services to store analytics information.</p>

          <p>The Privacy Policy of those linked services can be found at:</p>
          <ul>
          <li><a href="https://firebase.google.com/policies/analytics">Firebase Analytics</a></li>
          <li><a href="https://www.google.com/policies/privacy/">Google Play Services</a></li>
          </ul>

          <h3>Log Data</h3>

          <p>I want to inform you that whenever you use my App, in a case of an error or crash I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my App, the time and date of your use of the App, and other statistics.</p>

          <h3>Cookies and Similar Trackers</h3>
          <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
          <p>This App does not use these identifiers explicitly. However, the app may use third party code and libraries that use “cookies” or similar methods to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this app.</p>

          <h3>Service Providers</h3>

          <p>I may employ third-party companies and individuals due to the following reasons:</p>

          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>

          <p>I want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

          <h3>Security</h3>

          <p>I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. All interaction from the app with the internet is done through HTTPS. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.</p>

          <h3>Links to Other Sites</h3>

          <p>This App may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

          <h3>Children’s Privacy</h3>

          <p>This App does not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.</p>

          <h3>Changes to This Privacy Policy</h3>

          <p>I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

          <h3>Contact Us</h3>

          <p>If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at hi@natalcharts.app.</p>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
